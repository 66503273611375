<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-advertisement"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_2"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_2"
        class="collapse"
        data-parent="#accordion-form-advertisement"
      >
        <div class="card-body">
          <div>
            <h5>
              {{
                $tc(
                  "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.TITE_NATIVE_ADS"
                )
              }}
            </h5>

            <hr />

            <div
              v-if="hasAccess('authorizedAds', 'read')"
              class="d-flex flex-column flex-sm-row align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25">{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_ADS")
              }}</label>

              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-model="formData.authorizedAds"
                  size="lg"
                  name="check-button"
                  switch
                  :disabled="!hasAccess('authorizedAds', 'write')"
                />
              </div>
            </div>

            <div class="d-flex flex-row justify-content-between">
              <div
                class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
              >
                <label class="mr-2 mb-0">{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_ENABLED_CAPPING"
                  )
                }}</label>

                <div class="d-flex flex-column">
                  <b-form-checkbox
                    v-model="isEnabledCapping"
                    size="lg"
                    name="check-button"
                    switch
                    @input="changeIsEnabledCapping"
                  />
                </div>
              </div>

              <div
                v-show="isEnabledCapping && hasAccess('capping', 'read')"
                class="flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
                :class="{ 'd-flex': isEnabledCapping }"
              >
                <label class="mr-2 mb-0"
                  >{{
                    $tc(
                      "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_CAPPING"
                    )
                  }}
                  <HelperTooltip icon="mdi-help-circle" text="Placeholder" />
                </label>

                <div class="d-flex flex-column w-75">
                  <b-input-group append="min">
                    <b-form-input
                      v-model="cappingNativeAds"
                      placeholder="Time"
                      type="number"
                      :state="checkCappingNativeAds"
                      class="form-control--grey"
                      :disabled="!hasAccess('capping', 'write')"
                    ></b-form-input>
                  </b-input-group>

                  <b-form-invalid-feedback :state="checkCappingNativeAds">
                    {{
                      $tc(
                        "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.ERROR_TIME_CAPPING"
                      )
                    }}
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <h5>
              {{
                $tc(
                  "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.TITLE_AUDIO_ADS"
                )
              }}
            </h5>

            <hr />
          </div>

          <div
            v-if="hasAccess('authorizedAudioAds', 'read')"
            class="d-flex flex-column flex-sm-row  align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25">{{
              $tc(
                "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LADEL_AUDIO_ADS"
              )
            }}</label>

            <div class="d-flex flex-column">
              <b-form-checkbox
                v-model="formData.authorizedAudioAds"
                size="lg"
                name="check-button"
                switch
                :disabled="!hasAccess('authorizedAudioAds', 'write')"
              />
            </div>
          </div>

          <div
            v-show="
              formData.authorizedAudioAds &&
                hasAccess('audioAdsBackfilled', 'read')
            "
            class="flex-column flex-sm-row align-items-sm-center mb-3"
            :class="{ 'd-flex': formData.authorizedAudioAds }"
          >
            <label class="mr-2 mb-0 w-25">{{
              $tc(
                "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_BACK_FILL"
              )
            }}</label>

            <div class="d-flex flex-column">
              <b-form-checkbox
                v-model="formData.audioAdsBackfilled"
                size="lg"
                name="check-button"
                switch
                :disabled="!hasAccess('audioAdsBackfilled', 'write')"
              />
            </div>
          </div>

          <div
            v-show="
              formData.authorizedAudioAds && hasAccess('audioAdsUrl', 'read')
            "
            class="flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            :class="{ 'd-flex': formData.authorizedAudioAds }"
          >
            <label class="mr-2 mb-0 w-25">{{
              $tc(
                "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_AUDIO_ADS"
              )
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="formData.audioAdsUrl"
                rows="8"
                class="form-control--grey"
                :disabled="!hasAccess('audioAdsUrl', 'write')"
              />
            </div>
          </div>

          <div
            v-show="
              formData.authorizedAudioAds &&
                hasAccess('audioAdsConfiguration', 'read')
            "
            class="flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            :class="{ 'd-flex': formData.authorizedAudioAds }"
          >
            <label class="mr-2 mb-0 w-25">{{
              $tc(
                "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_AUDIO_ADS_CONFIGURATION"
              )
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-textarea
                v-model="formData.audioAdsConfiguration"
                rows="8"
                class="form-control--grey"
                :disabled="!hasAccess('audioAdsConfiguration', 'write')"
              />
            </div>
          </div>

          <div class="d-flex flex-row justify-content-between">
            <div
              v-if="hasAccess('capping', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0">{{
                $tc(
                  "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_ENABLED_CAPPING"
                )
              }}</label>

              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-model="isEnabledCappingAudioAds"
                  size="lg"
                  name="check-button"
                  switch
                  @input="changeIsEnabledCappingAudioAds"
                  :disabled="!hasAccess('capping', 'write')"
                />
              </div>
            </div>

            <div
              v-show="isEnabledCappingAudioAds && hasAccess('capping', 'read')"
              class="flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
              :class="{ 'd-flex': isEnabledCappingAudioAds }"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.LABEL_CAPPING"
                  )
                }}
                <HelperTooltip icon="mdi-help-circle" text="Placeholder" />
              </label>

              <div class="d-flex flex-column w-75">
                <b-input-group append="min">
                  <b-form-input
                    v-model="capingAudioAds"
                    placeholder="Time"
                    type="number"
                    :state="checkCappingAudiosAds"
                    class="form-control--grey"
                    :disabled="!hasAccess('capping', 'write')"
                  ></b-form-input>
                </b-input-group>

                <b-form-invalid-feedback :state="checkCappingAudiosAds">
                  {{
                    $tc(
                      "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.ERROR_TIME_CAPPING"
                    )
                  }}
                </b-form-invalid-feedback>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column">
            <div
              v-if="hasAccess('brand', 'read')"
              class="flex-column flex-sm-row justify-content-between align-items-sm-center mb-3 d-flex"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVERTISEMENT.BRAND")
                }}
              </label>

              <div class="d-flex flex-column w-75">
                <b-form-select
                  v-model="formData.brand"
                  :options="brandList"
                  class="form-control--grey"
                  value-field="id"
                  text-field="name"
                  :disabled="!hasAccess('brand', 'write')"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringifyJson } from "@/assets/js/helper";
import HelperTooltip from "@/components/HelperTooltip";
import { FORM_ADVERTISEMENT_PLAYLIST } from "@/common/config/acl/programs/playlist/abac";
import {
  hasAttributeAccess,
  getAttributesRead
} from "@/common/config/acl/index";
import { FORM_ADVERTISEMENT } from "@/common/config/acl/programs/abac";
import { getBrands } from "@/api/brands/getters";

const defaultAudioAdsConfiguration = {};

export default {
  props: {
    form: {
      type: Object,
      required: false
    },
    programId: {
      type: String,
      required: false
    },
    domains: {
      type: Array,
      required: true,
      default: () => []
    },
    program: {
      type: Object,
      required: true,
      default: () => {}
    },
    isCanCheck: {
      type: Boolean,
      required: false
    },
    currentUser: {
      type: Object,
      required: false
    }
  },

  components: {
    HelperTooltip
  },

  data() {
    return {
      formData: getAttributesRead(
        this.isFormPlaylist ? FORM_ADVERTISEMENT_PLAYLIST : FORM_ADVERTISEMENT,
        this.currentUser.roles
      ),
      isEnabledCapping: false,
      isEnabledCappingAudioAds: false,
      cappingNativeAds: "",
      capingAudioAds: "",
      loading: {
        brands: false
      },
      brandList: []
    };
  },

  computed: {
    checkCappingNativeAds() {
      if (
        this.isCanCheck &&
        this.isEnabledCapping &&
        this.cappingNativeAds !== ""
      ) {
        return parseInt(this.cappingNativeAds) > 0;
      }

      return true;
    },

    checkCappingAudiosAds() {
      if (
        this.isCanCheck &&
        this.isEnabledCappingAudioAds &&
        this.capingAudioAds !== ""
      ) {
        return parseInt(this.capingAudioAds) > 0;
      }

      return true;
    },

    hasErrors() {
      return !this.checkCappingNativeAds || !this.checkCappingAudiosAds;
    }
  },

  mounted() {
    if (this.programId) {
      this.formData.authorizedAds = this.form.authorizedAds;
      this.formData.authorizedAudioAds = this.form.authorizedAudioAds || false;
      this.formData.audioAdsBackfilled = this.form.audioAdsBackfilled || false;
      this.formData.audioAdsUrl = this.form.audioAdsUrl || "";
      this.formData.audioAdsConfiguration =
        stringifyJson(this.form.audioAdsConfiguration) ||
        stringifyJson(defaultAudioAdsConfiguration);
      this.formData.brand = this.program?.brand?.id || null;

      if (this.form.capping?.native_ad) {
        this.isEnabledCapping = this.form.capping.native_ad?.classic
          ? true
          : false;
        this.cappingNativeAds = this.form.capping.native_ad?.classic
          ? this.form.capping.native_ad?.classic.duration
          : "";
      }

      if (this.form.capping?.audio_ad) {
        this.isEnabledCappingAudioAds = this.form.capping.audio_ad?.classic
          ? true
          : false;
        this.capingAudioAds = this.form.capping.audio_ad?.classic
          ? this.form.capping.audio_ad.classic.duration
          : "";
      }
    }
    this.loadBrands();
  },

  methods: {
    resetData() {
      this.formData = getAttributesRead(
        this.isFormPlaylist ? FORM_ADVERTISEMENT_PLAYLIST : FORM_ADVERTISEMENT,
        this.currentUser.roles
      );
      this.cappingNativeAds = "";
      this.capingAudioAds = "";
      this.isEnabledCapping = false;
      this.isEnabledCappingAudioAds = false;
    },
    hasAccess(field, accessType) {
      return hasAttributeAccess(
        this.isFormPlaylist ? FORM_ADVERTISEMENT_PLAYLIST : FORM_ADVERTISEMENT,
        this.currentUser.roles,
        field,
        accessType
      );
    },
    changeIsEnabledCapping(value) {
      if (!value) {
        this.cappingNativeAds = "";
      }
    },
    changeIsEnabledCappingAudioAds(value) {
      if (!value) {
        this.capingAudioAds = "";
      }
    },
    async loadBrands() {
      const mainDomainProgram = this.domains.find(
        element => element.isPrimaryDomain === true
      );

      if (mainDomainProgram) {
        this.loading.brands = true;
        this.brandList = [];

        try {
          const response = await getBrands(
            mainDomainProgram.domain.company.id,
            this.axios,
            this.filters
          );
          this.brandList = response.data || [];
        } catch (error) {
          console.error("---ERROR-BRANDS---");
          console.error(error);
        }

        this.loading.brands = false;
      }
    }
  }
};
</script>
